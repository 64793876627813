import React, { useState, useEffect, useRef } from 'react';
import { useHistory, withRouter, RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router-dom';

// Styles
import { StyledQuestionnaire, StyledForm } from './questionnaire.styled';

// Components
import ConfirmSubmission from '../confirmSubmission/confirmSubmission';
import { QuestionContainer } from './components/questionContainer/questionContainer';
import { HeaderContainer } from './components/HeaderContainer/HeaderContainer';

// Modals
import WelcomeModal from '../modals/welcomeModal/welcomeModal';
import SavedModal from '../modals/savedModal/savedModal';
import QuestionSkipModal from '../modals/questionSkipModal/questionSkipModal';

// Models/Constants
import { IQuestion } from '../../models/questionaire';
import { IQuestionnaireResponse, IAnswers } from '../../models/questionnaireResponse';
import {
  backToQuestions,
  dismissWelcome,
  handleHideSkipModal,
  handleSkip,
  submitResponse,
  calculateCurrentIndex
} from './helper';
import { SESSION_KEY } from '../../constants';

interface QuestionnaireProps extends RouteComponentProps<any> {
  questionnaireType: string;
  questions: IQuestion[];
  answers: IAnswers;
  jurisdiction: string;
}

function Questionnaire({ questionnaireType, questions, answers, jurisdiction }: QuestionnaireProps) {
  const history = useHistory();
  const isFirstRun = useRef(true);
  const [firstLaunch, setFirstLaunch] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const blankSubject = {
    reference: 'Patient',
    display: 'Prostate cancer patient',
    patientId: ''
  };
  const [selectedOptions, setSelectedOptions] = useState<IQuestionnaireResponse>({
    resourceType: 'QuestionnaireResponse',
    questionnaire: questionnaireType,
    status: answers.status || '',
    subject: answers.subject || blankSubject,
    authored: answers.authored,
    item: answers.item,
    dpTimeline: answers.dpTimeline
  });
  const [showSkipModal, setShowSkipModal] = useState<boolean>(false);
  const [isResponseDisabled, setIsResponseDisabled] = useState<boolean>(false);
  const [skippingQuestion, setSkippingQuestion] = useState<IQuestion>({} as IQuestion);
  const [showSavedModal, setShowSavedModal] = useState<boolean>(false);

  useEffect(() => {
    // calculate the current index based on the answers
    setCurrentIndex(calculateCurrentIndex(answers));
  }, []);

  useEffect(() => {
    if (!isFirstRun.current) {
      currentIndex < 2 ? setFirstLaunch(true) : setFirstLaunch(false);
    }
  }, [currentIndex]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else {
      submitResponse(selectedOptions);
    }
  }, [selectedOptions]);
  return (
    <>
      <WelcomeModal
        isOpen={firstLaunch}
        dismiss={() => {
          dismissWelcome(setFirstLaunch);
        }}
        numberOfQuestions={questions.length}
        questionnaire={questionnaireType}
        jurisdiction={jurisdiction}
      />
      <StyledQuestionnaire>
        {/* Skipping questions using the URL can potentially return incorrect data, so we want to stop users from being able to do this.
        If user modifies the url to change questions, then history.location.key=undefined and history.action=POP.
        */}
        {!history.location.key && history.action === 'POP' && <Redirect to="/" />}
        <HeaderContainer setShowSavedModal={setShowSavedModal} />
        <StyledForm id="form" scrollBy={(currentIndex - 1) * -100}>
          {questions.map((_, index) => (
            <QuestionContainer
              key={index}
              index={index}
              currentIndex={currentIndex}
              isResponseDisabled={isResponseDisabled}
              jurisdiction={jurisdiction}
              questions={questions}
              selectedOptions={selectedOptions}
              setCurrentIndex={setCurrentIndex}
              setSelectedOptions={setSelectedOptions}
              setIsResponseDisabled={setIsResponseDisabled}
              setShowSkipModal={setShowSkipModal}
              setSkippingQuestion={setSkippingQuestion}
            />
          ))}
          <ConfirmSubmission
            selectedOptions={selectedOptions}
            questions={questions}
            questionnaire={questionnaireType}
            back={() => backToQuestions(history, currentIndex, setCurrentIndex, setIsResponseDisabled)}
          />
        </StyledForm>
      </StyledQuestionnaire>
      <QuestionSkipModal
        isOpen={showSkipModal}
        onHideModal={() => {
          handleHideSkipModal(setIsResponseDisabled, setShowSkipModal);
        }}
        onSkipQuestion={() => {
          handleSkip(
            history,
            skippingQuestion,
            questions,
            currentIndex,
            setCurrentIndex,
            selectedOptions,
            setSelectedOptions,
            setIsResponseDisabled
          );
        }}
      />
      <SavedModal isOpen={showSavedModal} />
    </>
  );
}

const q = withRouter(Questionnaire);

export { q as Questionnaire };
